<template>
  <v-responsive min-width="1302">
    <v-app class="bgr_clr_f9f9f9">
      <div class="bgr_clr_f9f9f9">
        <div class="padd-t-8 padd-b-8 padd-l-24">
          <span class="fsize12 clr-1867c0">Create Project / New  Create Project Creation</span>
        </div>
        <div class="padd-t-16 padd-b-16 padd-l-24">
          <span class="fsize20 clr-000 fw-bold">Create Project</span>
        </div>
        <div class="margin_bottom-7rem">
          <div class="marg-t-16 padd-l-24 padd-r-24">
            <v-card class="wholeCard padd-t-24 padd-0-40 min-height_1308">
              <v-row class="marg-0">
                <!-- <div class="marg-t-16"> -->
                <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                  <div class="fsize14 clr-000 fw-bold padd-t-24">
                    General Details
                  </div>
                </v-col>
                <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                  <div class="padd-t-24">
                    <div class="w-464 h-72">
                      <div class="custom-label fsize14 clr_55585a">Name</div>

                      <input
                        type="text"
                        name=""
                        class="
                          w-464
                          h-40
                          border_radius
                          padd-8-16
                          border-input
                          fsize14
                          outLine_none
                        "
                        placeholder="Enter your Name"
                        autocomplete="off"
                      />
                    </div>
                    <div class="marg-t-16">
                      <div class="w-464 h-72">
                        <div class="custom-label fsize13 clr_55585a">
                          AADHAR CARD
                        </div>

                        <input
                          type="text"
                          name=""
                          class="
                            w-464
                            h-40
                            border_radius
                            padd-8-16
                            border-input
                            fsize14
                            outLine_none
                          "
                          placeholder="Enter your AADHAR CARD"
                          autocomplete="off"
                        />
                      </div>
                    </div>

                    <v-row class="marg-0 marg-t-10">
                      <!-- <div class="marg-t-16"> -->

                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        xs="6"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div class="h-72">
                          <div class="custom-label fsize14 clr_55585a">
                            Mobile Number
                          </div>

                          <input
                            type="text"
                            name=""
                            class="
                              w-225
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            placeholder="Enter your Mobile Number"
                            autocomplete="off"
                          />
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        xs="6"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div class="h-72 marg-l-12 w-225">
                          <div class="custom-label fsize14 clr_55585a">
                            Whatsapp Number
                          </div>

                          <input
                            type="text"
                            name=""
                            class="
                              w-225
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            placeholder="Enter your  Whatsapp Number"
                            autocomplete="off"
                          />
                          <!-- <v-select
                            class="fsize14 marg_top w-225"
                            :items="educationalItem"
                            label=""
                            dense
                            outlined
                          ></v-select> -->
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                </v-col>
                <!-- </div> -->
              </v-row>
              <hr class="lineBelowHead marg-t-32" />

              <!-- --------------------second Line----------------------------- -->
              <v-row class="marg-0">
                <!-- <div class="marg-t-16"> -->
                <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                  <div class="fsize14 clr-000 fw-bold padd-t-24">
                    Personal Details
                  </div>
                </v-col>
                <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                  <div class="padd-t-24">
                    <div class="w-464 h-72">
                      <div class="custom-label fsize14 clr_55585a">
                        Customer Type
                      </div>

                      <v-select
                        class="fsize14 marg_top w-464"
                        :items="educationalItem1"
                        label=""
                        dense
                        outlined
                      ></v-select>
                    </div>
                    <div class="marg-t-16">
                      <div class="w-464 h-72">
                        <div class="custom-label fsize13 clr_55585a">Area</div>

                        <v-select
                          class="fsize14 marg_top w-464"
                          :items="educationalItem"
                          label=""
                          dense
                          outlined
                        ></v-select>
                      </div>
                    </div>
                    <div class="marg-t-16">
                      <div class="w-464 h-72">
                        <div class="custom-label fsize13 clr_55585a">
                          Married
                        </div>

                        <v-select
                          class="fsize14 marg_top w-464"
                          :items="educationalItem"
                          label=""
                          dense
                          outlined
                        ></v-select>
                      </div>
                    </div>

                    <v-row class="marg-0 marg-t-10">
                      <!-- <div class="marg-t-16"> -->

                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        xs="6"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div class="h-72">
                          <div class="custom-label fsize14 clr_55585a">
                            Spouse Name
                          </div>

                          <input
                            type="text"
                            name=""
                            class="
                              w-225
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            placeholder="Enter your Spouse Name"
                            autocomplete="off"
                          />
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        xs="6"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div class="h-72 marg-l-12 w-225">
                          <div class="custom-label fsize14 clr_55585a">
                            Spouse Mob No
                          </div>

                          <!-- <v-select
                            class="fsize14 marg_top w-225"
                            :items="educationalItem"
                            label=""
                            dense
                            outlined
                          ></v-select> -->
                          <input
                            type="text"
                            name=""
                            class="
                              w-225
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            placeholder="Enter your Spouse Name"
                            autocomplete="off"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                </v-col>
                <!-- </div> -->
              </v-row>
              <hr class="lineBelowHead marg-t-32" />
              <!-- ----------------------Third Line-------------------------------------------- -->
              <v-row class="marg-0">
                <!-- <div class="marg-t-16"> -->
                <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                  <div class="fsize14 clr-000 fw-bold padd-t-24">
                    Link AADHAR via DigiLocker
                  </div>
                </v-col>
                <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                  <div class="padd-b-28 padd-t-24">
                    <v-btn
                      depressed
                      plain
                      retain-focus-on-click
                      ripple
                      class="btn-clr textTransform_None bgr_clr_btn_cancel"
                    >
                      Link Aadhar
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                </v-col>
                <!-- </div> -->
              </v-row>
              <hr class="lineBelowHead" />
              <v-row class="marg-0">
                <!-- <div class="marg-t-16"> -->
                <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                  <div class="fsize14 clr-000 fw-bold padd-t-24">
                    Bank Account Details
                  </div>
                </v-col>
                <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                  <div class="padd-t-24 row marg-0">
                    <div class="w-400 h-72">
                      <span class="custom-label fsize14 clr_55585a">
                        IFSC Code
                      </span>

                      <!-- <div class="row marg-0"> -->
                      <input
                        type="text"
                        name=""
                        class="
                          w-400
                          h-40
                          border_radius
                          padd-8-16
                          border-input
                          fsize14
                          outLine_none
                        "
                        placeholder="Enter your Name"
                        autocomplete="off"
                      />
                    </div>
                    <!-- </div> -->
                    <div class="marg-t-24 marg-l-10">
                      <v-btn
                        class="
                          bgr_clr_btn_save
                          textTransform_None
                          min_width_48
                          h-40
                        "
                        depressed
                        plain
                        retain-focus-on-click
                        ripple
                      >
                        Go
                      </v-btn>
                    </div>

                    <div class="marg-t-16">
                      <div class="w-464 h-72">
                        <div class="custom-label fsize13 clr_55585a">
                          Bank Name
                        </div>

                        <input
                          type="text"
                          name=""
                          class="
                            w-464
                            h-40
                            border_radius
                            padd-8-16
                            border-input
                            fsize14
                            outLine_none
                          "
                          placeholder="Enter your Bank Name"
                          autocomplete="off"
                        />
                      </div>
                    </div>

                    <div class="marg-t-16">
                      <div class="w-464 h-72">
                        <div class="custom-label fsize13 clr_55585a">
                          Bank Address
                        </div>

                        <v-select
                          class="fsize14 marg_top w-464"
                          :items="educationalItem"
                          label=""
                          dense
                          outlined
                        ></v-select>
                      </div>
                    </div>

                    <div class="marg-t-16">
                      <div class="w-464 h-72">
                        <div class="custom-label fsize13 clr_55585a">
                          Account Number
                        </div>

                        <input
                          type="text"
                          name=""
                          class="
                            w-464
                            h-40
                            border_radius
                            padd-8-16
                            border-input
                            fsize14
                            outLine_none
                          "
                          placeholder="Enter your Account Number"
                          autocomplete="off"
                        />
                      </div>
                    </div>

                    <div class="marg-t-16">
                      <div class="w-464 h-72">
                        <div class="custom-label fsize13 clr_55585a">
                          Confirm Account Number
                        </div>

                        <input
                          type="text"
                          name=""
                          class="
                            w-464
                            h-40
                            border_radius
                            padd-8-16
                            border-input
                            fsize14
                            outLine_none
                          "
                          placeholder="Enter your Name"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                </v-col>
                <!-- </div> -->
              </v-row>
              <hr class="lineBelowHead marg-t-32" />

              <!-- <div class="fsize14 clr-000"> Pettioner Detail</div> -->
            </v-card>
          </div>

          <div class="">
            <v-row class="marg-0">
              <!-- <div class="marg-t-16"> -->
              <v-col cols="10" sm="10" md="10" xs="10" class="pa-0 padd-0">
              </v-col>
              <v-col cols="2" sm="2" md="2" xs="2" class="pa-0 padd-0">
                <div class="row marg-t-20">
                  <div class="w-96">
                    <v-btn
                      class="
                        btn_width_96
                        h-40
                        brl_clr_btn
                        fsize14
                        textTransform_None
                        bgr_clr_btn_cancel
                      "
                      depressed
                      plain
                      retain-focus-on-click
                      ripple
                    >
                      Cancel
                    </v-btn>
                  </div>
                  <div class="marg-l-16 w-96">
                    <v-btn
                      class="
                        btn_width_96
                        h-40
                        brl_clr_btn
                        fsize14
                        textTransform_None
                        bgr_clr_btn_save
                      "
                      depressed
                      plain
                      retain-focus-on-click
                      ripple
                    >
                      Save
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-app>
  </v-responsive>
</template>

<script>
export default {
  data() {
    return {
      educationalItem: ["UG", "Graduate", "PG", "Professional", "others"],
      educationalItem1: ["UG", "Graduate", "PG", "Professional", "others"],
    };
  },
};
</script>

<style>
.marg-0 {
  margin: 0px !important;
}
.fsize14 {
  font-size: 14px !important;
}
.clr-1867c0 {
  color: #1867c0;
}
.clr-000 {
  color: #000000 !important;
}
.fw-bold {
  font-weight: bold !important;
}
.padd-t-16 {
  padding-top: 16px;
}
.padd-b-16 {
  padding-bottom: 16px;
}
.padd-t-8 {
  padding-top: 8px;
}
.padd-b-8 {
  padding-bottom: 8px;
}
.padd-l-24 {
  padding-left: 24px;
}
.padd-r-24 {
  padding-right: 24px;
}
.marg-t-16 {
  margin-top: 16px;
}
.bgr_clr_f9f9f9 {
  background-color: #f9f9f9 !important;
}
.padd-t-24 {
  padding-top: 24px !important;
}
.padd-0-40 {
  padding: 0px 40px !important;
}
.h-40 {
  height: 40px !important;
}
.h-72 {
  height: 72px;
}
.w-464 {
  width: 464px;
}
.border-input {
  border: solid 1px #a3a3a3;
  box-shadow: none !important;
}
.border_radius {
  border-radius: 4px !important;
}
/* .v-card  {
  min-height: 1308 !important;
} */
.wholeCard.v-card {
  min-height: 1200px !important;
}
.padd-8-16 {
  padding: 8px 0px 8px 16px !important ;
}
.w-225 {
  width: 225px !important;
}
.marg-l-12 {
  margin-left: 12px !important;
}
.marg-l-16 {
  margin-left: 16px !important;
}
.fsize13 {
  font-size: 13px;
}
.lineBelowHead {
  border-top: 1px solid#f9f9f9;
}
.marg-t-32 {
  margin-top: 32px !important;
}
.clr_55585a {
  color: #55585a !important;
}
.w-225.v-input__control {
  width: 225px !important;
}
.w-464.v-input__control {
  width: 464px !important;
}
.v-input .v-label {
  font-size: 14px !important;
}
.v-messages {
  min-height: 0px !important;
}
.btn-clr {
  color: #007aff !important;
  border: 1px solid #007aff !important;
}
.padd-b-28 {
  padding-bottom: 28px;
}
/* .theme--light.v-btn.v-btn--has-bg {
  background-color: #fff !important;
} */
.textTransform_None {
  text-transform: none !important;
}
.margin_bottom-7rem {
  margin-bottom: 7rem !important;
}
.marg-t-20 {
  margin-top: 20px !important;
}
.marg-t-24 {
  margin-top: 24px !important;
}

.brl_clr_btn {
  border: 1px solid #2e3031 !important;
}
.bgr_clr_btn_save {
  background-color: #007aff !important;
  color: #fff !important;
  border: 1px solid #007aff !important;
}
.bgr_clr_btn_cancel {
  background-color: #fff !important;
}
.btn_width_96 {
  min-width: 96px !important;
}
.fsize20 {
  font-size: 20px !important;
}
.w-400 {
  width: 400px !important;
}
.min_width_48 {
  min-width: 48px !important;
}
.max_w-225 {
  max-width: 225px !important;
}
/* .v-btn--is-elevated  */

.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)
  .v-btn__content {
  opacity: unset !important;
}
.outLine_none {
  outline: none !important;
}
.marg-l-10 {
  margin-left: 10px !important;
}
.marg-t-10{
    margin-top: 10px !important;
}
</style>